@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: normal;
}

code {
  font-family: 'Poppins';
}

/* width */
::-webkit-scrollbar {
  /* width: 2px;
  height: 2px; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.text-error {
  color: red !important;
}

.text-amber {
  color: #FFBF00 !important;
}

body {
  background: #fff !important;
  font-family: 'Poppins'!important;
}
body,p,button,li,a,label,span, div {
  font-family: 'Poppins'!important;
}

.MuiSnackbar-root {
  top: 90px !important;
  right: 25px !important;
}

.displayEllipsis {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
.slid_ar {
  position: relative;
}
.slid_ar .slid_bt {
  top: 33%;
  position: absolute;
}
.slid_ar_left button{
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #008bff;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  left: 0px;
}
.slid_ar_right button{
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #008bff;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  right: 0px;
}
.slid_ar_right button:hover, .slid_ar_left button:hover {
  background: #0168be;
}
.slid_ar_right button svg path, .slid_ar_left button svg path {
  fill: #fff;
  stroke: #fff;
}
.cat_bt_l {
  top: 40%;
  position: absolute;
  left: -10px;
}
.cat_bt_r {
  top: 40%;
  position: absolute;
  right: -37px;
}
.cat_bt_l button{
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #008bff;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);

}
.cat_bt_r button{
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: #008bff;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
}
.cat_bt_l button:hover, .cat_bt_r button:hover {
  background-color: #0168be;
}
.cat_bt_l button svg path, .cat_bt_r button svg path {
  fill: #fff;
  stroke: #fff;
}
.cu_card {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%)!important;
  border-radius: 10px!important;
  position: relative;
}
.wis_io span {
  position: absolute;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  right: 10px;
  top: 12px;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}
.wis_io span svg {
  font-size: 20px;
  color: #777777;
}
.wis_io_list span {
  position: absolute;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  left: 10px;
  top: 12px;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}
.wis_io_list span svg {
  font-size: 20px;
  color: #777777;
}
.cu_card img {
  max-width: 100%;
  width: 100%;
  height: 130px;
  max-height: 130px;
  object-fit: contain;
  border-radius: 5px;
  margin: 0 !important;
}
.ell_single{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
body .makeStyles-categoryNameTypo-202{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto !important;
  margin-bottom: 12px;
}
.btn_grid{
  border: 1px solid #103f68 !important;
  background: #103f68 !important;
  color: #fff !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px;
  border-radius: 5px !important;
  padding: 0!important;
}
.btn_grid.MuiButton-colorInherit {
  border: 1px solid #103f68 !important;
  background: transparent !important;
  color: #103f68 !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px;
  border-radius: 5px !important;
  padding: 0!important;
}
.btn_grid.MuiButton-colorInherit svg path {
  stroke: #103f68;
  fill: #103f68
}
.btn_grid:hover {
  border: 1px solid #103f68 !important;
  background: transparent !important;
  color: #103f68 !important;
}
.btn_list {
  border: 1px solid #103f68 !important;
  background: transparent !important;
  color: #103f68 !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px;
  border-radius: 5px !important;
  padding: 0!important;
}
.btn_list.MuiButton-colorPrimary {
  border: 1px solid #103f68 !important;
  background: #103f68 !important;
  color: #fff !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px;
  border-radius: 5px !important;
  padding: 0!important;
}
.btn_list.MuiButton-colorPrimary svg path {
  stroke: #fff;
  fill: #fff
}
.btn_list:focus {
  color: #103f68 !important;
}
.btn_list:hover {
  border: 1px solid #103f68 !important;
  background: #103f68 !important;
  color: #fff !important;
}
.fil_gr {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%)!important;
  padding: 20px 10px;
  border-radius: 10px!important;
}
.fil_gr .MuiChip-root{
  background-color: transparent;
  display: block;
  height: auto;
  padding: 10px;
  margin-bottom: 2px !important;
  border-radius: 5px!important;
  margin-right: 0!important;
}
.fil_gr .MuiChip-root:hover { 
  border-radius: 5px!important;
  background-color: #f9f9f9;
}
.fil_gr .MuiChip-root span {
  height: auto;
  display: block;
  font-size: 16px;
}
.fil_gr .MuiChip-root span svg{
  float: right;
}
.se_fild {
  position: relative;
}
.se_fild .MuiOutlinedInput-root {
  border: 1px solid #cdcdcd !important;
  background: transparent !important;
  color: #000 !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 100%;
  border-radius: 5px !important;
  padding: 0 !important;
}
.se_fild .MuiAutocomplete-endAdornment button{
  width: auto!important;
  height: auto !important;
}
body .se_fild .Mui-focused fieldset {
  border: none !important;
  box-shadow: none !important;
}
.se_fild input {
  padding-left: 35px;
}
.se_fild .se_sv {
  position: absolute;
  top: 10px;
  left: 10px;
}
.ad_cr_gir button {
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  border-radius: 0px 0px 10px 10px;
  height: 38px;
  font-family: 'Poppins';
}
button {
  font-family: 'Poppins'!important;
}
.menu_draw {
  width: 400px;
  left: auto !important;
}
.menu_draw .MuiToolbar-root button{
  background-color: transparent!important;
}
.menu_draw .MuiToolbar-root button svg {
  stroke: #ffffff;
}
.check_fl {
  position: relative;
}
.check_fl .MuiOutlinedInput-root {
  border: 1px solid #cdcdcd !important;
  background: transparent !important;
  color: #000 !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 100%;
  border-radius: 5px !important;
  padding: 0 !important;
}
.check_fl input{
  font-size: 14px;
}
body .check_fl .Mui-focused fieldset {
  border: none !important;
  box-shadow: none !important;
}
.check_fl .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-left: 9px !important;
}
.chek_bx span {
  border: none!important
}
.chek_bx .MuiCheckbox-root{
  margin-left: 16px!important;
}
.chek_bx svg{
  margin-right: 10px!important;
}
.p_0{
  padding: 0!important;
}
.cou_in .MuiOutlinedInput-root {
    border: 1px solid #cdcdcd !important;
    background: #fff !important;
    border-radius: 0!important;
}
.cou_in {
  position: relative;
}
.cou_in button {
  position: absolute;
  right: 10px;
  top: 9px;
  padding: 0;
  height: auto;
  border-radius: 0;
}
.acd_sz .MuiAccordionSummary-root,.acd_sz .MuiPaper-elevation0 {
  min-height: auto!important;
}
.acd_sz .MuiAccordionSummary-content {
  margin-bottom: 0!important;
}
.acd_sz {
  margin-top: 0!important;
}
.of_s .offerCode {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
}
.off_s_tt{
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.cat_of {
  display: flex;
  overflow: hidden;
}
.cat_of .MuiPaper-elevation {
  margin: 0px 10px;
  width: 120px;
}
.cat_of .cat_bt_l {
  left: 37px;
}
.cat_of .cat_bt_r{
  right: 0;
}
.h_soci a {
  width: 40px;
  height: 40px;
  padding-top: 8px;
  margin: 5px 14px 5px 0;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  color: #103f68!important;
}
.ft_st a,.ft_st div {
  font-weight: 300!important;
}
.ft_st a:hover {
  font-weight: 300!important;
  text-decoration: none;
}
.log_card{
  background-color: #ffffff;
  padding: 30px;
  border-radius: 32px;
  border: 1px solid #D2D2D2;
}
.log_card label {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Poppins';
}
.log_card .input_formControl__ubdUb input {
  border-radius: 0px!important;
}
.log_card input, .log_card .input_formControl__ubdUb{
  border: 1px solid #dadada;
}
.log_card .errorMessage_error_message_text__BEgPi {
  padding-left: 0px!important;
  padding-top: 0px!important;
}
.log_card .input_error__lXzdM {
  border: 1px solid #ff5959!important;
}
.btn_log {
  width: 100%;
  display: block;
}
.btn_log button {
  width: 100%!important;
  display: block;
  max-width: 100%!important;
  min-width: 100%!important;
  border-radius: 10px;
  background-color: #1c75bc!important;
  transition: .5s;
}
.btn_log button:hover {
  background-color: #007bff!important;
}
.btn_log button p {
  color: #fff!important;
}
.prof_i {
  width: 130px;
  height: 130px;
  margin-top: -50px;
  border-radius: 50%;
}
.prof_i img {
  border: 3px solid #fff;
  object-fit: cover;
}
.ed_pf_bx {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #D2D2D2;
}
.up_l_pd label {
  width: auto;
  height: 38px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  transition: .5s;
  padding: 8px 15px;
  border: 1px solid #103f68;
}
.up_l_pd label:hover {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
  color: #000;
}
.prof_up_in .MuiOutlinedInput-root {
  border: 1px solid #cdcdcd !important;
  background: transparent !important;
  color: #000 !important;
  height: 40px !important;
  min-width: 40px !important;
  width: 100%;
  border-radius: 5px !important;
  padding: 0 !important;
}
body .prof_up_in .Mui-focused fieldset {
  border: none !important;
  box-shadow: none !important;
}
.prof_up_in input {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.prof_up_text .MuiOutlinedInput-root {
  border: 1px solid #cdcdcd !important;
  background: transparent !important;
  color: #000 !important;
  width: 100%;
  border-radius: 5px !important;
  padding: 0 !important;
}
body .prof_up_text .Mui-focused fieldset {
  border: none !important;
  box-shadow: none !important;
}
.pr_suv button{
  border: 1px solid #103f68 !important;
  background: #103f68 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 8px !important;
  transition: .5s;
}
.pr_suv button p{
  color: #fff !important;
  transition: .5s;
}
.pr_suv button:hover{
  background: transparent !important;
  color: #103f68 !important;
  transition: .5s;
}
.pr_suv button:hover p{
  color: #103f68 !important;
}
.pop_ct {
  list-style: none;
}
.pop_ct {
  text-align: center;
}
.pop_ct a {
  display: inline-block;
  position: relative;
  padding: 0px 15px;
  font-size: 14px;
  color: #fff;
  margin-top: 15px;
}
.pop_ct a:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #cdcdcd;
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
}
.pop_ct a:first-child::after {
  display: none;
}
.ch_psd {
  border-radius: 15px;
}
.po_bann {
  background-image: url(./assets/images/banner-10.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  padding: 90px 70px;
  border-radius: 15px;
}
.nw_in {
  position: relative;
}
.nw_in img {
  position: absolute;
  right: 0px;
  bottom: -90px;
  max-width: 100%;
}
.off_sspl {
  background-color: #eee;
  padding: 15px;
  border-radius: 10px;
  min-height: 103px;
}
.off_sspl img {
  width: 55px;
  height: 55px;
  object-fit: contain;
}
.fit_sc .MuiOutlinedInput-root {
  border: 1px solid #cdcdcd !important;
  background: transparent !important;
  color: #000 !important;
  width: 100%;
  border-radius: 5px !important;
  padding: 0 !important;
}
body .fit_sc .Mui-focused fieldset {
  border: none !important;
  box-shadow: none !important;
}
.fit_sc .MuiOutlinedInput-root svg {
  margin-left: 8px;
  font-size: 17px;
}
.fit_sc input {
  padding-left: 0px;
}