/* src/styles/searchByCity.module.scss */

.searchForm {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.formControl {
  width: 100%;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
}

button[type="reset"] {
  background-color: #f44336;
  color: #fff;
  border: none;
}

